.DivButton{
    display: flex;
    justify-content: right;
    width:100%;
    margin-bottom: 12px;
}

.ActionElements{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.ActionElements a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}