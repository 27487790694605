.selectProgressBar {
    width: 50%;
}

.listProgressBar {
    //width: calc(100% - 46px);
}

.FractionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #7A8893;
    text-align: end;
    position: relative;
    justify-content: flex-end;
    min-height: 32px;
}

.FractionContainer > span {
    position: absolute;
    right: 0;
    top: 0;
}