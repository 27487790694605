.Card {
    height: 100%;
}

.Password {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 12px;
}

.Password:hover {
    background-color: #efefef;
    border-radius: 10px;
}

.Password > div:nth-child(1) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.Password > span:nth-child(2) {
    font-size: 25px;
}
