.HorizontalFormItem {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.RadioSelect {
    display: flex;
    justify-content: flex-end;
}

.StepNavigation {
    text-align: right;
}