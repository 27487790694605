.VisualizerWindow {
    flex: 2 0 50%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.VisualizerWindow:hover {
    border-color: rgba(122, 136, 147, 0.8);
}

.ImageContainer {
    height: calc(100% - 64px);
    width: 100%;
    display: flex;
    justify-content: center;
}

.ImageContainer img {
    height: calc(100% - 1px);
    image-rendering: pixelated;
    object-fit: contain;
}

.HalfHeight {
    height: 50% !important;
    width: 49%;
}

.Active {
    border-color: #38b6ff !important;
}

.FloatingIndicator {
    font-size: 14px;
    position: absolute;
    top: 64px;
    left: 10px;
    display: flex;
    flex-direction: column;
    opacity: 50%;
}

.FloatingIndicatorMedium{
    font-size: 12px;
    position: absolute;
    top: 64px;
    left: 10px;
    display: flex;
    flex-direction: column;
    opacity: 50%;
}

.FloatingIndicatorSmall{
    font-size: 10px;
    position: absolute;
    top: 64px;
    left: 10px;
    display: flex;
    flex-direction: column;
    opacity: 50%;
}

.FloatingIndicator:hover {
    transition: 0.2s;
    opacity: 100%;
}

.FloatingIndicator div {
    text-align: left;
}

.VisualizerWindowHeader {
    width: 100%;
    border-radius: 8px 8px 0px 0px solid rgba(122, 136, 147, 0.3);
    display: flex;
    justify-content: flex-end;
    padding: 2px 10px;
    margin-bottom: 3px;
}

.VisualizerWindowHeader a {
    text-decoration: none;
    font-size: 16px;
}

.VisualizerWindowHeader span {
    font-size: 16px;
    color: #7a8893;
    cursor: pointer;
}

.VisualizerWindowHeader span:hover {
    color: #abb9c5;
}
