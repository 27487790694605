.Button button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
}

.Button button:active{
    border-color: #38b6ff !important;
    color: #38b6ff;
    background-color: #38b6ff;
    transition: all 0.2ms ease-in;
}


