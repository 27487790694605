.Data {
    display: flex;
    flex-direction: row;
}

.Data div {
    min-width: 30%;
}

.InfoText {
    display: flex;
    align-items: center;
}

.InfoText > span:nth-child(1) {
    font-size: 20px;
    padding-right: 10px;
    color: #7a8893;
}
