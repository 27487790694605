.noLicense {
    color: #DDDDDD;
    font-size: small;
}

.disabled {
    color: #6d767d;
}

.textContainer {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    p {
        max-width: 300px;
    }
}

.space {
    margin-right: 5px;;
}

.buttonIcon {
    margin-left: 4px;
}
