.Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 16px 0 0;
}

.Text {
    color: #fff;
    margin-right: 8px;
}

.Select {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background-color: #3c4048;
    border-style: solid;
    border-color: #7a8893;
    border-width: 1px;
}

.Select:hover {
    border-color: #38b6ff;
}
