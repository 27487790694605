.SubTable {
    background-color: #F9F9F9;
    color: #6D767D;
}

.Tag {
    margin-left: 1%;
    background-color: #E0F4FF;
    color: #38B6FF;
    border: none;
}

.Icon {
    color: #7A8893;
}

.ButtonContainer{
    display: flex;
    justify-content: right;
}