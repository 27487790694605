.HorizontalFormItem {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.Row {
    display: flex;
}

.Col {
    flex: 1 1 auto;
    width: 50%;
}

.Col:nth-child(1) {
    margin-right: 22px;
}

.LicenseBox {
    background-color: #F9F9F9;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 30px 20px;
    display: flex;
    gap: 40px;
}

.LicenseEntry {
    flex-grow: 1;
}


.LicenseEntry > div {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 36px;
}

.FractionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #7A8893;
    text-align: end;
    position: relative;
    justify-content: flex-end;
    min-height: 32px;
}

.FractionContainer > span {
    position: absolute;
    right: 0;
    top: 0;
}

.ChangeButton {
    margin-right: 16px;
    background-color: #132D4A;
}