.TextContainer {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    p {
        max-width: 300px;
    }
}

.ButtonIcon {
    margin-left: 4px;
}

.spaceBetweenButtons {
    margin-right: 15px;
}

.email {
    color: #6D767D;
    font-size: small;
}
