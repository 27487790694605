.row {
    display: flex;
}

.col {
    flex: 1 1 auto;
    width: 50%;
}

.col:nth-child(1) {
    margin-right: 22px;
}

.invalidEmail {
   color: red;
}