.MainImage{
    image-rendering: pixelated;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.MainCanvas{
    cursor: crosshair;
}

.PixelAnalizeImageContainer{
    position: relative;
}

.Container{
    display: flex;
    align-items: center;
    justify-content: center;
}