.Container {
    position: absolute;
    z-index: 30;
    right: 20px;
    width: 400px;
}

.Container > div:nth-child(2) {
    padding: 0;
    min-height: 80px;
    max-height: 600px;
    overflow: auto;
}
