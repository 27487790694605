@import "variables.scss";

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf)
        format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf)
        format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf)
        format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v11/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf)
        format("truetype");
}

body {
    font-family: "Inter", sans-serif;
}

img {
    max-width: 100%;
}

.logo {
    height: 88px;
    background: $main-bg-color;
}

.site-layout-sub-header-background {
    background: $main-bg-color;
}

.site-layout-background {
    background: $main-bg-color;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: $dark-bg-color;
}

/* antd adaptation */
.ant-menu-item-selected {
    .ant-menu-title-content {
        color: #132d4a;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }
}

.ant-menu-title-content {
    font-size: 16px;
}

/* hide upload bar */

.ant-upload-list-item {
    display: none;
}

/* steps */

.ant-steps-item-title {
    font-weight: 600;
    
    &:before {
        content: "";
    }
}

/* back arrow menu */
.ant-page-header-back-button {
    height: 32px;
    width: 32px;
    background-color: $dark-bg-color !important;
    border-radius: 50%;
    color: $main-bg-color;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.ant-card {
    box-shadow: 0 4px 10px 0 #0e2d3f1a;
}

.button-icon {
    margin-left: 6px;
}


.ant-steps-item-title {
  width: 100%;
}
/*
.ant-steps-item-title::before {
  content: " ";
  height: 1px;
  background: black;
  display: block;
  position: absolute;
  width: 100px;
  right: 0;
}*/

.ant-menu-title-content {
    white-space: normal;
}
.ant-menu-item {
    line-height: 20px !important;
}