.Footer {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.Footer a {
    text-decoration: none;
    color: #8b9396;
}

.buttonIcon {
    margin-left: 4px;
}
