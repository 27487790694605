.stepNavigation {
    text-align: right;
}
.buttonCreate{
    margin-left: 10px;
}
.buttonLater{
    border: 1px solid #A9B6BF;
}
.row {
    display: flex;
}
.col {
    flex: 1 1 auto;
    width: 50%;
}
.col:nth-child(1) {
    margin-right: 22px;
}
.alert {
    margin-bottom: 15px;
}