.TagLocation {
    position: relative;
}

.Tag {
    position: absolute;

    bottom: -15px;
    background-color: rgba(226, 203, 255, 0.4);
    border: 1px solid #e2cbff;
    border-radius: 50px;
    padding: 4px 15px;
    font-size: 12px;
    width: fit-content;
}

.RoundedTag {
    border-radius: 20px;
    padding: 0 12px;
}
