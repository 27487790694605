.Col {
    min-width: 300px;
}

.Title {
    margin: 0 !important;
}

.StepNavigation {
    text-align: right;
}

.ArchiveIcon {
    font-size: 42px;
    color: #38B6FF
}