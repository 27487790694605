.MainPanel > div:nth-child(2) > div {
    padding: 0;
}

.SecondaryCollapse > div > div:nth-child(1) {
    padding: 0 16px !important;
}

.SecondaryCollapse {
    width: 100%;
}

.SecondaryCollapse > div > div > div > span {
    color: #38b6ff;
}

.SecondaryCollapse > div > div {
    color: #fff !important;
    font-size: 16px;
}

.Panel {
    display: flex;
    flex-direction: column;
}

.Panel > div:nth-child(2) {
    overflow-y: auto;
}

.Panel > div:nth-child(2)::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
}

.Panel > div:nth-child(2)::-webkit-scrollbar-track {
    background: #292b32;
}

.Panel > div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #3e4046;
    border-radius: 10px;
}

.DarkButton {
    background-color: #3c4048;
    color: #fff;
    border: none;
    height: 40px;
}

.DarkButton:hover {
    background-color: #434852;
    color: #fff;
}

.DarkButton:focus {
    background-color: #434852;
    color: #fff;
}

.DarkButton > span:nth-child(2) {
    color: #38b6ff;
}
