.HorizontalFormItem {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.StepNavigation {
    text-align: right;
}

.Row {
    display: flex;
}

.Col {
    flex: 1 1 auto;
    width: 50%;
}

.Col:nth-child(1) {
    margin-right: 22px;
}

.TecniqueInfo {
    margin-bottom: 30px;
}

.Button {
    display: flex;
    margin-left: 40%;
    font-weight: 500;
    font-size: medium;
    align-items: center;
}

.ButtonIcon {
    margin-left: 3px;
}

.Title {
    display: flex;
    justify-content: space-between;
}