.ReloadLicenceButtonContainer{
    display: flex;
    justify-content: right;
}

.Row {
    display: flex;
}

.Col {
    flex: 1 1 auto;
    width: 50%;
}

.Col:nth-child(1) {
    margin-right: 22px;
}

.InputForm{
    display: flex;
    width: 100%;
    text-align: left;
}

.InputForm:nth-child(1) {
    margin-right: 22px;
}

.LabelForm{
    width: 100%;
    display: flex;
    flex: auto;
}

.TextInfo{
    font-weight: bold;
}