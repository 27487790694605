.FileListContainer {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    max-width: 400px;
    p {
        padding: 0 8px;
        margin: 0;
        flex-grow: 1;
    }
}

.FileListContainer:hover {
    background-color: rgba(0, 0, 0, 0.05);
}