@font-face {
  font-family: 'kenko';
  src:  url('fonts/kenko.eot?ox5kza');
  src:  url('fonts/kenko.eot?ox5kza#iefix') format('embedded-opentype'),
    url('fonts/kenko.ttf?ox5kza') format('truetype'),
    url('fonts/kenko.woff?ox5kza') format('woff'),
    url('fonts/kenko.svg?ox5kza#kenko') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="kenko-icon-"], [class*=" kenko-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kenko' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kenko-icon-pixel:before {
  content: "\e91e";
}
.kenko-icon-curves:before {
  content: "\e91d";
}
.kenko-icon-edit:before {
  content: "\e91b";
}
.kenko-icon-plus:before {
  content: "\e91c";
}
.kenko-icon-sequences:before {
  content: "\e91a";
}
.kenko-icon-warning:before {
  content: "\e919";
}
.kenko-icon-world:before {
  content: "\e918";
}
.kenko-icon-simple-right-arrow:before {
  content: "\e917";
}
.kenko-icon-stats:before {
  content: "\e916";
}
.kenko-icon-mail:before {
  content: "\e915";
}
.kenko-icon-open:before {
  content: "\e914";
}
.kenko-icon-folder:before {
  content: "\e913";
}
.kenko-icon-right-arrow:before {
  content: "\e912";
}
.kenko-icon-bin:before {
  content: "\e911";
}
.kenko-icon-info:before {
  content: "\e910";
}
.kenko-icon-history:before {
  content: "\e904";
}
.kenko-icon-clip:before {
  content: "\e908";
}
.kenko-icon-archive-empty:before {
  content: "\e909";
}
.kenko-icon-plus1:before {
  content: "\e90a";
}
.kenko-icon-eye-crossed:before {
  content: "\e90b";
}
.kenko-icon-eye:before {
  content: "\e90c";
}
.kenko-icon-image:before {
  content: "\e90d";
}
.kenko-icon-bell-inactive:before {
  content: "\e90e";
}
.kenko-icon-people:before {
  content: "\e90f";
}
.kenko-icon-archive-filled:before {
  content: "\e900";
}
.kenko-icon-archive-line:before {
  content: "\e901";
}
.kenko-icon-person:before {
  content: "\e902";
}
.kenko-icon-tool:before {
  content: "\e903";
}
.kenko-icon-logout:before {
  content: "\e905";
}
.kenko-icon-download:before {
  content: "\e906";
}
.kenko-icon-document:before {
  content: "\e907";
}
