.Card {
    background-color: #f9f9f9;
    border-radius: 0;
}

.TitleBar {
    display: flex;
    justify-content: space-between;
}

.Row {
    display: flex;
}

.Col {
    flex: 1 1 auto;
    width: 50%;
}

.Col:nth-child(1) {
    margin-right: 22px;
}

.Select {
    
}

.Trash {
    color: grey;
}

