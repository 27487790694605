.Container > div {
    display: flex;
    flex-direction: row;
}

.DetailContainer {
    display: flex;
    align-items: center;
    gap: 6px;
}

.Circle {
    height: 10px;
    width: 10px;
    border-radius: 12px;
}

.ColumnRing {
    min-width: 220px;
    min-height: 200px;
    gap: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UnderRingText {
    font-size: 16px;
    text-align: center;
}

.ProgressContainer {
    position: relative;
}

.Progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Progress h1 {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
}

.Progress p {
    margin: 0;
}
