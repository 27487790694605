.SizeExtraSmall {
    padding: 5px;
}
.SizeSmall {
    padding: 10px;
}
.SizeMedium {
    padding: 15px;
}
.SizeBig {
    padding: 25px;
}