.Tag {
    border: 1px solid #7a8893;
    width: fit-content;
    padding: 3px 12px;
    margin: 10px 0;
    border-radius: 8px;
    cursor: pointer;
}

.Tag:hover {
    background-color: #3c4048;
}

.Active {
    color: #38b6ff;
    border-color: #38b6ff;
}
