.Disabled {
    color: #6d767d;
}

.TextContainer {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    p {
        max-width: 300px;
    }
}

.buttonIcon {
    margin-left: 6px;
}

.resetButton {
    color: #38b6ff;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.okButton {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rangePicker {
    margin: 10px 10px 0 10px;
}

.rangePickerRow {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: 7px 10px 0px 0px;
}

.filterIcon {
    color: #38b6ff;
}

.noFilterIcon {
}

.headerRow {
    display: flex;
    justify-content: space-between;
}