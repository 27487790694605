.Container {
    height: Calc(100vh - 100px);
    
}

.ButtonDiv {    
    display: flex;
    justify-content: center;
}

.Button {
    width: 90%;
    font-weight: 500;
}

.ButtonIcon {
    color:  #38B6FF;
    padding-left: 2%;
    align-items: center;
}

.Edit {
    cursor: pointer;
}