.input {
    width: 40%;
}

.text {
    margin-left: 10px;
}

.stepNavigation {
    text-align: right;
}