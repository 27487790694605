.Container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Col {
    width: 50%;
}

.Tabs {
    padding: 0 15px;
}

.buttonIcon {
    margin-left: 4px;
}
