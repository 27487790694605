.TitleSkeleton {
    max-width: 300px;
}

.TitleSkeleton > div > h3 {
    background-color: #dddddd !important;
}

.Skeleton > div > h3 {
    background-color: #dddddd !important;
}

.RoundedTag {
    border-radius: 20px;
    padding: 0 12px;
}

.Disabled {
    color: #6d767d;
}

.DeleteButton {
    width: 100%;
    border: 1px solid #A9B6BF;
}

.DeleteButton > span {
    margin-left: 5px;
}