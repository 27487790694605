.HorizontalFormItem {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.Row {
    display: flex;
}

.Col {
    flex: 1 1 auto;
    width: 50%;
}

.Col:nth-child(1) {
    margin-right: 22px;
}

.Link {
    text-decoration: underline;
}
