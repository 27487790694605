.Skeleton > div > h3 {
    background-color: #dddddd !important;
}

.ReloadButton{
    display: flex;
    width: 100%;
    justify-content: center;
}

.ButtonsRow {
    display: flex;
    justify-content: space-between;
}

.EditButton {
    width: 50%;
    border: 1px solid #A9B6BF;
    transition: none !important;
}

.AloneEditButton {
    width: 100%;
    border: 1px solid #A9B6BF;
    transition: none !important;
}

.DeleteButton {
    width: 50%;
    border: none;
    transition: none !important;
}

.EditIcon {
    color: #38B6FF;
    margin-left: 5%;
}

.DeleteIcon {
    color: #7A8893;
    margin-left: 5%;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
}

.Button {
    all: unset;
    padding: 8px 20px;
    border: #a9b6bf 1px solid;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.Button span {
    color: #38b6ff;
}

.Button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}