.CenteredContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.CenteredContainer p {
    font-size: 20px;
    margin-right: 18px;
    margin-bottom: 5px;
    text-align: center;
}
