.Container{
    margin: 0 16px 0 0;
}

.Button button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border-width: 1px 1px 1px 1px;
}

.Button button{
    background-color: #64C255;
}

.Button button:disabled{
    color:#d5d5d5;
    background-color: #919191;
}

.Button button:disabled:hover{
    color:#d5d5d5;
    background-color: #919191;
}

.Text {
    color: #fff;
    margin-right: 8px;
}

.Switch switch{
    position: relative !important;
    top: 0px !important;
}