//xs-s-m-l-xl (fonts-icons)
$icon-size-m:24px; 

//icon
$dark-icon-color:#132D4A;

//bg-title-text
$main-bg-color:#fff;
$dark-bg-color:#141414;

//paddings


//margins

