.Container {
    height: Calc(100vh - 100px);
}

.ModalButtonsDiv{
    display: flex;
    justify-content: end;
    margin-top: 16px;
}

.ModalButton{
    margin-left: 8px;
}