.Card {
    height: 100%;
}

.Language {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
}

.Language > div:nth-child(2) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.Language > div:nth-child(2) > h5 {
    margin: 0;
}

.Language > div:nth-child(3) {
    font-size: 25px;
}

.Language > div:nth-child(1) {
    font-size: 25px;
}
