.Container {
    background-color: #1f2127;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 80px);
}

.Container > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(122, 136, 147, 0.3);
    border-radius: 10px;
    text-align: center;

    height: 100%;
}

.Container > div > div {
    text-align: center;
}

.HalfHeight {
    height: 50%;
}

.Image {
    width: 100%;
    height: 100%;
    max-width: calc(100vh - 160px);
    object-fit: contain;
}
