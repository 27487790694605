.VisualizerWindow {
    flex: 2 0 50%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    //border: none !important;
}

.HalfHeight {
    height: 50% !important;
    width: 49%;
}