.TextContainer {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    p {
        max-width: 300px;
    }
}

.progress {
    width: calc(100% - 46px);
}

.email {
    color: #6D767D;
    font-size: small;
}

.margin {
    margin-right: 7px;
}

.customPopup {
    max-height: 200px;
    overflow-y: auto;
}