.VideoBox {
    background-color: #d9d9d9;
    height: 250px;
    width: 100%;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.VideoBox img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: brightness(50%);
    border-radius: 25px;
}

.VideoBox button {
    position: absolute;
}

.VideoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
