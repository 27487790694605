.Header {
    background-color: #1f2127;
    color: #fff !important;
}

.Header > div > div > span {
    color: #fff !important;
}

.Header > div > div > div > div {
    background-color: rgba(0, 0, 0, 0) !important;
}

.Header > div > div > div > div svg {
    color: #7a8893;
}