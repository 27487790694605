.Content {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.Content img {
    width: 50%;
}

.ErrorTextBox {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ErrorTextBox h1 {
    margin: 20px 0 !important;
}

.Subtext {
    font-size: 16px !important;
}

.Link {
    color: #38b6ff;
    font-size: 16px;
    width: fit-content;
}

.Link span {
    margin-right: 5px;
}
