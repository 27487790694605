.Card {
    height: 100%;
}

.Card > div {
    height: 100%;
}

.Container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ContainerSimple {
    height: 100%;
    display: flex;
    flex-direction: column;
}


.Description {
    height: 100%;
}

.FractionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #7a8893;
    text-align: end;
    position: relative;
    justify-content: flex-end;
    min-height: 32px;
}



.FractionContainer > span {
    position: absolute;
    right: 0;
    top: 0;
}
