.Container {
    min-height: 300px;
}

.StepNavigation {
    text-align: right;
}

.AssignLaterButton{
    margin: 0 8px;
}