.Container {
    padding: 20px;
    border: 1px solid #eaeaec;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.Container > span {
    height: fit-content;
}

.Content {
    cursor: pointer;
    display: flex;
    gap: 12px;
}

.Read {
    cursor: default !important;
    opacity: 60%;
}

.Active {
    cursor: pointer;
}

.Dot {
    border-radius: 10px;
    background-color: #38b6ff;
    height: 10px;
    min-width: 10px;
    margin-top: 6px;
}

.NoDot {
    border-radius: 10px;
    height: 10px;
    min-width: 10px;
    margin-top: 6px;
}
