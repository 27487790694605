.Buttons {
    display: flex;
    gap: 15px;
}

.label {
    display:flex;
    justify-content: center;
    align-items: center;
}
