.ButtonsRow {
    display: flex;
    justify-content: space-between;
}

.EditButton {
    width: 50%;
    border: 1px solid #A9B6BF;
    transition: none !important;
}

.AloneEditButton {
    width: 100%;
    border: 1px solid #A9B6BF;
    transition: none !important;
}

.DeleteButton {
    width: 50%;
    border: none;
    transition: none !important;
}

.EditIcon {
    color: #38B6FF;
    margin-left: 5%;
}

.DeleteIcon {
    color: #7A8893;
    margin-left: 5%;
}