.remainingCases {
    color: #6D767D;
    font-size: small;
}

.user {
    color: #6D767D;
    font-size: small;
    font-style: italic;
}

.inputNumber {
    width: 100%;
}

.cases {
    font-size: x-small;
}

.progress {
    width: 40%;
}

