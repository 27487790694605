.Sider {
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    background-color: #1f2127;
    color: #fff;
}

.Collapse > div > div {
    color: #fff !important;
}

.MainCollapse {
    background-color: #292b32;
    padding-right: 10px;
    margin: 10px;
    border-radius: 10px;
    height: calc(100vh - 100px);
    overflow: hidden;
}

.MainPanel > div:nth-child(2) > div {
    padding: 0;
}

.MainPanelDisabled{
    color:#5c5c5c !important;
}

.MainCollapse > div > div {
    color: #fff !important;
}
