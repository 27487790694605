.Container {
    height: 30px;
    width: 100%;
    margin-bottom: 10px;
}

.StepBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    padding: 0 8px;
}

.Step {
    width: 1px;
    height: 6px;
    background-color: #a9b6bf;
}

.Slider {
    width: 100%;
    -webkit-appearance: none;
    height: 10px;
    border-radius: 5px;
    background: none;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.Slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 0;
    background: url("../../../../../assets/images/sliderThumb.svg") no-repeat;
    background-size: contain;
    cursor: pointer;
}

.Slider::-moz-range-thumb {
    width: 23px;
    height: 24px;
    border: 0;
    background: url("../../../../../assets/images/sliderThumb.svg");
    cursor: pointer;
}
