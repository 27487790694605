.remainingCases {
    color: #6D767D;
    font-size: small;
}
.select {
    width: 50%;
}

.progress {
    width: 40%;
}
