.TitleSkeleton {
    max-width: 300px;
}

.TitleSkeleton > div > h3 {
    background-color: #dddddd !important;
}

.Skeleton > div > h3 {
    background-color: #dddddd !important;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.Button {
    all: unset;
    padding: 8px 20px;
    border: #a9b6bf 1px solid;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Button span {
    color: #38b6ff;
}

.Button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
